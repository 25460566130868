<template>
  <div class="content-wrapper">
    <div class="payroll main-content">
      <div class="page-header">
        <h1>Staff Payroll Information</h1>
      </div>

      <div class="form-wrapper">
        <div class="action-button-wrapper">
          <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
          <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
        </div>

        <div class="card info-card">
          <el-form ref="payroll-form" :disabled="disabled" :model="form" label-width="200px">
            <div class="row">
              <el-form-item label="Employee Name">
                <el-input :disabled="true" v-model="form.name"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="Salary Type" style="max-width: 550px;">
                <el-select v-model="form.salaryType" placeholder="Select the type">
                  <el-option label="Monthly Salary" value="Monthly Salary"></el-option>
                  <el-option label="Annual Aalary" value="Annual Aalary"></el-option>
                  <el-option label="Hourly Rate" value="Hourly Rate"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item label="Base Salary" style="max-width: 550px;">
                <el-input type="number" v-model="form.basicSalary">
                  <template slot="prepend">$</template>
                </el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>

      <h2 class="card-title">Addition Payroll</h2>
      <div class="card info-card">
        <div class="orders-list">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Pay Item</th>
                <th scope="col">Amount</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="payroll in additionPayrolls">
                <td>{{ payroll.pay_item }}</td>
                <td>${{payroll.amount}}</td>
                <td>{{formatDateTime(payroll.create_date, 'YYYY-MM-DD HH:mm:ss')}}</td>
                <td class="action">
                  <router-link :to="{name:'AdditionPayroll', params: {id: payroll.id}}">View</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Financial from '@/lib/financial';
import Hr from '@/lib/hr';

export default {
  name: 'Payroll',
  mounted(){
    this.loadEmployee();
    this.loadAdditionPayrolls();
  },
  data(){
    let form = {
      name: '',
      salaryType: '',
      basicSalary: '',
    };
    return {
      disabled: true,
      additionPayrolls: [],
      form,
    };
  },
  methods:{
    startEdit(){
      this.disabled = false;
    },
    async save(){
      const updatedEmployee = await this.updateEmployee();
      this.disabled = true;
    },
    async loadAdditionPayrolls(){
      const loginInfo = Common.getLoginInfo();
      try{
        const employeeId = this.$route.params.id;
        const searchFields = {
          'employee_id': employeeId,
        };
        const payrolls = await Financial.searchAdditionPayrolls(this.apiUrl, searchFields, loginInfo);
        this.additionPayrolls = payrolls;
      }catch(err){
        console.log(err);
      }
    },
    async loadEmployee(){
      const loginInfo = Common.getLoginInfo();
      try{
        const employeeId = this.$route.params.id;
        const employee = await Hr.loadEmployee(this.apiUrl, employeeId, loginInfo);
        this.form = {
          name: employee.employee_name,
          salaryType: employee.salary_type,
          basicSalary: employee.base_salary_amount,
        };
      }catch(err){
        console.log(err);
      }
    },
    async updateEmployee(){
      try{
        const loginInfo = Common.getLoginInfo();
        const employeeId = this.$route.params.id;
        const updateFields = Object.assign({}, this.form);
        const employee = await Hr.updateEmployee(this.apiUrl, employeeId, updateFields, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    ...mapActions(['setCurrentEmployee']),
  },
  computed: mapState({
    currentEmployee: state => state.hr.currentEmployee,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">

.info-card{
  padding: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.contact-info{
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;

  .info-card{
    padding-left: 30px;
  }
}

.form-wrapper{
  margin-bottom: 50px;
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
